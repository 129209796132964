import "./JobItem.scss"
import user from "images/Company/Career/user.svg"
import calender from "images/Company/Career/calendar.svg"
import { useFormatter } from "helpers/i18n"

export default function JobItem({ name, level, deadline, pathJob }) {
  const { __, Link } = useFormatter()
  return (
    <>
      <div className="job-item-border">
        <div className="job-info">
          <Link className="item-title" to={pathJob}>
            {name}
          </Link>
          <div className="item-level">
            <div className="item-level-info">
              <img src={user} />
              <span className="level-name">{level}</span>
            </div>
            <div className="item-level-info">
              <img src={calender} />
              <span className="level-name">{deadline}</span>
            </div>
          </div>
        </div>
        <div className="view">
          <Link to={pathJob}>
            {__({
              defaultMessage: "View",
            })}
          </Link>
        </div>
      </div>
    </>
  )
}
