import React, { useState, useEffect } from "react"
// import useCollapse from "react-collapsed"
import JobItem from "./JobItem"
import "./FilterJobs.scss"
import { useFormatter } from "helpers/i18n"

const maxDisplayJob = 3

export default function FilterJobs({ data, is_tech }) {
  const { __ } = useFormatter()
  const [isTech, setIsTech] = useState(is_tech)
  const [filterJobs, setFilterJobs] = useState()
  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  useEffect(() => {
    setFilterJobs(data?.filter(item => item.is_tech === isTech))
  }, [isTech])

  return (
    <>
      <div className="filter-jobs">
        <button
          className={isTech && `is-selected`}
          onClick={() => setIsTech(true)}
        >
          Tech
        </button>
        <button
          className={!isTech && `is-selected`}
          onClick={() => setIsTech(false)}
        >
          Non-Tech
        </button>
      </div>
      <div className="job-list">
        {filterJobs?.map((item, idx) => {
          if (idx < maxDisplayJob) {
            return <JobItem {...item} key={idx} />
          }
        })}
        {/* <section {...getCollapseProps()}>
          {filterJobs?.map((item, idx) => {
            if (idx >= maxDisplayJob) {
              return <JobItem {...item} key={idx} />
            }
          })}
        </section> */}
      </div>

      {filterJobs?.length > maxDisplayJob && (
        <div className="see-more">
          {/* <button {...getToggleProps()}> */}
          {/* {isExpanded
              ? `${__({
                  defaultMessage: "See less",
                })}`
              : `${__({
                  defaultMessage: "See more",
                })}`} */}
          {/* </button> */}
        </div>
      )}
    </>
  )
}
